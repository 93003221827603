import { PostData as ApiPostData } from "../../services/Api.service";
import moment from 'moment-timezone';
import { sendExceptionEmail } from "./Helpers";
moment.tz.setDefault("America/phoenix");

export const periodObservance = async function (timeTrackingData) {
    try {
        if (process.env.REACT_APP_SERVER_ENVIRONMENT === "production") {

            let taskId = '';
            let slug = '';

            if (timeTrackingData.taskData && timeTrackingData.taskData.length > 0) {
                const index = timeTrackingData.taskData.findIndex(task => task.slug === timeTrackingData.slug);
                if (index >= 0) {
                    taskId = timeTrackingData.taskData[index].taskId;
                    slug = timeTrackingData.taskData[index].slug;
                }
            }

            let data = {
                taskId: taskId,
                slug: slug,
                userId: timeTrackingData.userData.user_id,
                companyId: timeTrackingData.userData.company_id,
                propertyId: timeTrackingData.orderData.property_id,
                reportId: timeTrackingData.orderData.id,
                orderStatus: timeTrackingData.orderData.order_status,
                closingTask: timeTrackingData.closingTask ? 'yes' : 'no',
                newTabCheck: timeTrackingData.newTabCheck ? 'yes' : 'no',
            };

            await ApiPostData(`orders/period-observance-add-queue`, data);
        }
    } catch (error) {
        await sendExceptionEmail(error, { componentName: 'TimeTrackingHelpers', functionName: 'periodObservance', data: timeTrackingData });
    }
}




