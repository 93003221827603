import { sendExceptionEmail } from "components/Common/Helpers";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Modal, UncontrolledTooltip } from "reactstrap";
import { PostData } from "services/Api.service";
import * as moment from "moment";

const PreviewEmail = ({ propButtonOptions, propRowData }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [htmlData, setHtmlData] = useState();

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e, row) => {
        e.preventDefault();
        setIsOpenModal(true);
        retrieveEmailPreview()
        setUpdate(moment())
    };

    const handleToggleModal = (state) => {
        setIsOpenModal(!state);
        setUpdate(moment());
    };

    const retrieveEmailPreview = async () => {
        try {
            const data = {
                status: propRowData.activeTab
            };

            const response = await PostData(`admin/email-logs/${propRowData.id}/preview`, data);

            setHtmlData(response.data.htmlData);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { componentName: 'PreviewEmail', functionName: 'retrieveEmailPreview', data: {} });
        }
    };

    return (
        <>
            <Button
                color={propButtonOptions?.color ? propButtonOptions.color : "primary"}
                outline={propButtonOptions?.outline ? propButtonOptions.outline : true}
                block={propButtonOptions?.block ? propButtonOptions.block : false}
                size={propButtonOptions?.size ? propButtonOptions.size : 'sm'}
                type="button"
                id={`view_error_${propRowData.id}`}
                onClick={e => handleActionClick(e)}
            >
                <i className="fa fa-eye" ></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target={`view_error_${propRowData.id}`} >See Details</UncontrolledTooltip>


            <Modal className="modal-dialog-centered modal-xl" isOpen={isOpenModal} toggle={() => handleToggleModal(isOpenModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center">
                        Preview
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body p-0 email-preview">
                    <div dangerouslySetInnerHTML={{ __html: htmlData }} />
                </div>
            </Modal>
        </>
    );
};

export default PreviewEmail;