import { createSlice } from '@reduxjs/toolkit'
import { defaultValues } from './PropertiesDetailsAPIHelper'

const initialStates = {
  initialDataRendered: false,
  propertiesData: [],
  selectedPropertyOnMap: null,
  totalRecords: 0,
  isProcess: false,
  isRendered: false,
  searchFilter: null,
  filterType: null,
  currentPage: 1,
  recordsPerPage: 25,
  totalRecordSize: 0,
  sortFieldName: 'id',
  sortDirection: 'desc',
  acpCount: 0,
  iipCount: 0,
  vipCount: 0,
  propertiesFilterParams: { ...defaultValues().propertyFilterParams },
  filtersApplied: false,
  displayProperties: {},
  subjectProperty: {},
}

export const PropertiesDetailsReducer = createSlice({
  name: 'PropertiesDetails',
  initialState: { ...initialStates },
  reducers: {
    setPropertiesDetailsData: (state, data) => { data?.payload?.forEach(element => { state[element.key] = element.value }) },
    setPropertiesDetailsFilterParams: (state, data) => { data?.payload?.forEach(element => { state.propertiesFilterParams[element.key] = element.value }) },
    resetForm: (state) => {
      for (const key in { ...initialStates }) {
        if (initialStates.hasOwnProperty(key)) {
          state[key] = initialStates[key]
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setPropertiesDetailsData,
  resetForm,
  setPropertiesDetailsFilterParams
} = PropertiesDetailsReducer.actions

export default PropertiesDetailsReducer.reducer