import React, { useEffect } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import Spinner from "components/Common/Spinner";
import { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import "./GoogleOrLatLongAddress.scss";
import { GOOGLE_MAP_API_URL } from "../Constants";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_ADDRESS_KEY);

const GoogleAddressOrLatLong = (props) => {

    let [isProcess, setProcess] = useState(false);
    let [orderInformation, setOrderInformation] = useState({});
    let [customErrors, setCustomErrors] = useState([]);

    useEffect(() => {
        props.cb(orderInformation);
    }, [orderInformation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [props.customErrors]); // eslint-disable-line react-hooks/exhaustive-deps

    let onDataChange = (e) => {

        let name = e.target.name;
        let value = e.target.value;

        let orderInformationData = {};
        orderInformationData[name] = value

        if (name === 'propertyUploadType') {
            orderInformationData.googleAddress = {};
            orderInformationData.latLongAddress = {};
        }

        let customErrorsArray = [...customErrors];
        if (value) {
            const index = customErrorsArray.indexOf(name);
            if (index > -1) {
                customErrorsArray.splice(index, 1);
                setCustomErrors(customErrorsArray)
            };
        }

        setOrderInformation(orderInformationData)
    };

    let handleGoogleAddressProcess = (place, cb) => {

        let googleAddressTemp = {
            formatted_address: place.formatted_address,
            google_latitude: place.google_latitude,
            place_id: place.place_id,
            // address_components: place.address_components,
        };

        for (let i = 0; i < place.address_components.length; i++) {

            if (place.address_components[i].types.includes("street_number")) {
                googleAddressTemp.address_1 = place.address_components[i].short_name;
            }
            if (place.address_components[i].types.includes("route")) {
                googleAddressTemp.address_1 = (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + " " : "") + place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("sublocality_level_2")) {
                googleAddressTemp.address_1 = (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + ", " : "") + place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("sublocality_level_1")) {
                googleAddressTemp.address_2 = place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("locality")) {
                googleAddressTemp.city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("administrative_area_level_1")) {
                googleAddressTemp.state = place.address_components[i].short_name;
            }
            if (place.address_components[i].types.includes("postal_code")) {
                googleAddressTemp.postal_code = place.address_components[i].short_name;
            }
            if (!googleAddressTemp.city && googleAddressTemp.address_2) {
                googleAddressTemp.city = googleAddressTemp.address_2;
            }
        }

        Geocode.fromAddress(place.formatted_address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;

                googleAddressTemp.street_view = `${GOOGLE_MAP_API_URL}/streetview?size=600x400&location=${lat},${lng}&fov=90&pitch=10&key=${process.env.REACT_APP_GOOGLE_ADDRESS_KEY}`;
                googleAddressTemp.google_latitude = lat;
                googleAddressTemp.google_longitude = lng;

                cb(googleAddressTemp);

            },
            (error) => {
                console.error(error);
            }
        );
    }

    let handleGoogleAddress = (place) => {
        handleGoogleAddressProcess(place, function (googleAddressTemp) {

            let orderInformationData = { ...orderInformation };
            orderInformationData.googleAddress = googleAddressTemp;
            setOrderInformation(orderInformationData)
        });
    };

    let validateLatLng = (lat, lng) => {
        let pattern = /^\S/;
        return pattern.test(lat) && pattern.test(lng)
    };

    let handleLatLong = (e) => {
        let latLng = e.target.value;
        let customErrorsTemp = [...customErrors];

        if (latLng) {
            setProcess(true)
            let latLngData = latLng.split(",");

            const index = customErrorsTemp.indexOf('lat_lng_validation');
            if (index > -1) { // only splice array when item is found
                customErrorsTemp.splice(index, 1); // 2nd parameter means remove one item only
                setCustomErrors(customErrorsTemp)
            };

            if (latLngData.length === 2) {

                let validateLatLngData = validateLatLng(latLngData[0], latLngData[1]);

                if (validateLatLngData) {
                    Geocode.fromLatLng(latLngData[0], latLngData[1]).then(
                        response => {
                            const addressData = response.results;


                            if (addressData.length > 0) {

                                let addressComponents = addressData[0].address_components;

                                let address = "";
                                let city = "";
                                let state = "";
                                let zip = "";

                                for (let i = 0; i < addressComponents.length; i++) {
                                    if (['street_number'].includes(addressComponents[i].types[0])) {
                                        address += addressComponents[i].long_name + " ";
                                    }
                                    if (['route'].includes(addressComponents[i].types[0])) {
                                        address += addressComponents[i].long_name;
                                    }
                                    if (['locality'].includes(addressComponents[i].types[0])) {
                                        city = addressComponents[i].long_name;
                                    }
                                    if (['administrative_area_level_1'].includes(addressComponents[i].types[0])) {
                                        state = addressComponents[i].short_name;
                                    }
                                    if (['postal_code'].includes(addressComponents[i].types[0])) {
                                        zip = addressComponents[i].long_name;
                                    }
                                }

                                let tempLatLongAddress = {
                                    address_1: address,
                                    city: city,
                                    state: state,
                                    postal_code: zip
                                };

                                let orderInformationData = { ...orderInformation };

                                tempLatLongAddress.latLng = latLng;
                                tempLatLongAddress.google_latitude = latLngData[0];
                                tempLatLongAddress.google_longitude = latLngData[1];
                                tempLatLongAddress.place_id = addressData[0].place_id;

                                orderInformationData.latLongAddress = tempLatLongAddress;

                                // handleGoogleAddressProcess(addressData[0], function (googleAddressTemp) {
                                // orderInformationData.googleAddress = googleAddressTemp;
                                setOrderInformation(orderInformationData)
                                setProcess(false);
                                // });
                            }
                        },
                        error => {
                            customErrorsTemp = [...customErrorsTemp];
                            customErrorsTemp.push("lat_lng_validation");
                            setCustomErrors(customErrorsTemp)
                            setProcess(false);
                        }
                    );

                } else {
                    customErrorsTemp = [...customErrorsTemp];
                    customErrorsTemp.push("lat_lng_validation");
                    setCustomErrors(customErrorsTemp)
                    setProcess(false);
                }

            } else {
                customErrorsTemp = [...customErrorsTemp];
                customErrorsTemp.push("lat_lng_validation");
                setCustomErrors(customErrorsTemp)
                setProcess(false);
            }
        }
    };

    let handleLatLongAddress = (e) => {
        let { name } = e.target;
        let orderInformationData = { ...orderInformation };

        let tempLatLongAddress = { ...orderInformationData.latLongAddress };

        if (name === 'address_1') {
            tempLatLongAddress.address_1 = e.target.value;
        }
        if (name === 'city') {
            tempLatLongAddress.city = e.target.value;
        }
        if (name === 'state') {
            tempLatLongAddress.state = e.target.value;
        }
        if (name === 'postal_code') {
            tempLatLongAddress.postal_code = e.target.value;
        }

        orderInformationData.latLongAddress = tempLatLongAddress;

        setOrderInformation(orderInformationData)

    };

    let handleClearAddress = () => {
        let orderInformationData = { ...orderInformation };
        orderInformationData.latLongAddress = { ...orderInformationData.latLongAddress }

        orderInformationData.latLongAddress.address_1 = "";
        orderInformationData.latLongAddress.city = "";
        orderInformationData.latLongAddress.state = "";
        orderInformationData.latLongAddress.postal_code = "";

        setOrderInformation(orderInformationData)
    };

    let clearGoogleAddress = (e) => {

        let orderInformationData = { ...orderInformation };

        orderInformationData.googleAddress = {};
        orderInformationData.latLongAddress = {};

        setOrderInformation(orderInformationData)
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" className="my-2">
                    <Label className="required">Property address type </Label>
                    <div className="custom-radio-btn inline">
                        <div >
                            <input type="radio" id='address' name="propertyUploadType" value='address'
                                onChange={(e) => onDataChange(e)}
                                checked={'address' === orderInformation.propertyUploadType}
                            />
                            <label htmlFor='address'>Property Address</label>
                        </div>
                        <div >
                            <input type="radio" id='lat-long' name="propertyUploadType" value='lat-long'
                                onChange={(e) => onDataChange(e)}
                                checked={'lat-long' === orderInformation.propertyUploadType}
                            />
                            <label htmlFor='lat-long'>Latitude-Longitude</label>
                        </div>
                    </div>
                    {/* {(customErrors.includes('propertyUploadType')) && <span className="form-error">This field is required.</span>} */}
                </Col>

                <Col xs="12" sm="12" md="12" lg="12">
                    {orderInformation.propertyUploadType === 'address' &&
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Row >
                                    <Col xs="12" sm="12" md="12" lg="6">
                                        <Label className="required">Address</Label>
                                        {orderInformation?.googleAddress?.formatted_address ?
                                            <>
                                                <Button className="clear-google-address-selection" size="sm" color="secondary" onClick={(e) => clearGoogleAddress(e)}><i className="fa fa-times"></i></Button>
                                                <AvField
                                                    name="address"
                                                    value={orderInformation?.googleAddress?.formatted_address}
                                                    disabled
                                                />
                                            </>
                                            :
                                            <ReactGoogleAutocomplete size="sm" className="form-control"
                                                apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_KEY}
                                                value={orderInformation?.googleAddress?.formatted_address}
                                                onPlaceSelected={(place) => handleGoogleAddress(place)}
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                                required
                                            />
                                        }
                                        {(customErrors.includes('google_address')) && <span className="form-error">This field is required.</span>}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    }

                    {orderInformation.propertyUploadType === 'lat-long' &&
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Row >
                                    <Col xs="12" sm="12" md="4" lg="4" className="no-form-group-padding">
                                        <Label className="required">Latitude Longitude</Label>
                                        <AvField bsSize="sm" name="get_address_from_lat_lng" placeholder="Ex: 00.000000,-00.000000"
                                            value={orderInformation.latLongAddress?.latLng ? orderInformation.latLongAddress?.latLng : ""}
                                            onChange={e => handleLatLong(e)}
                                            validate={{
                                                maxLength: { value: 50, errorMessage: "Max length must be 50." }
                                            }} />

                                        {(customErrors.includes('lat_long')) && <span className="mt--3 form-error">This field is required.</span>}
                                        {(customErrors.includes('lat_lng_validation')) && <span className="mt--3 form-error">Please enter valid latitude and longitude.</span>}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12" sm="12" md="3" lg="4">
                                        <AvField bsSize="sm" name="address_1" label="Address" placeholder="Enter Address" value={orderInformation.latLongAddress?.address_1} onChange={(e) => handleLatLongAddress(e)} />
                                    </Col>
                                    <Col xs="12" sm="12" md="3" lg="3">
                                        <AvField bsSize="sm" name="city" label="City" placeholder="Enter City" value={orderInformation.latLongAddress?.city} onChange={(e) => handleLatLongAddress(e)} />
                                    </Col>
                                    <Col xs="12" sm="12" md="2" lg="2">
                                        <AvField bsSize="sm" name="state" label="State" placeholder="Enter State" value={orderInformation.latLongAddress?.state} onChange={(e) => handleLatLongAddress(e)} />
                                    </Col>
                                    <Col xs="12" sm="12" md="2" lg="2">
                                        <AvField bsSize="sm" name="postal_code" label="Zip" placeholder="Enter Zip" value={orderInformation.latLongAddress?.postal_code} onChange={(e) => handleLatLongAddress(e)} />
                                    </Col>
                                    <Col xs="12" sm="12" md="1" lg="1" className="mt-4">
                                        <span className="clear-address" onClick={handleClearAddress}>x</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>
    );
};

export default GoogleAddressOrLatLong;