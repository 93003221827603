import React, { Suspense, useEffect, useState } from "react";
import { useLocation, Route, Switch, useHistory, matchPath } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { handleUserRedirection, loggedInUser } from "../components/Common/Helpers"
import Spinner from "components/Common/Spinner";
import { useSelector } from 'react-redux'

const Admin = (props) => {

	const spinnerCurrentStat = useSelector((state) => state.showHideSpinner.currentStat)

	const mainContent = React.useRef(null);
	const location = useLocation();
	const userData = loggedInUser();
	let history = useHistory();

	const [isCollapsedSideBar, setIsCollapsedSideBat] = useState(props?.systemSettings?.isCollapsedSideBar ? props?.systemSettings?.isCollapsedSideBar : false);

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	useEffect(() => {
		const redirect = handleUserRedirection(userData, location)
		if (redirect) {
			console.log("redirect", redirect)
			history.push(redirect);
		}
	}, [history, userData, location]);

	const getRoutes = (routes) => {
		let navMenu = [];
		routes.forEach(menu => {
			navMenu.push(menu);
			if (menu.children) {
				menu.children.forEach(subMenu => {
					navMenu.push(subMenu);
				});
			}
		});

		return navMenu.map((prop, key) => {
			if (prop.layout === "/admin" && prop.is_visible === true) {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			const fullPath = routes[i].layout + routes[i].path;

			// Use matchPath to check dynamic routes
			if (matchPath(props.location.pathname, { path: fullPath, exact: true })) {
				return routes[i].name;
			}

			if (routes[i]?.children?.length) {
				for (let j = 0; j < routes[i].children.length; j++) {
					const childFullPath = routes[i].children[j].layout + routes[i].children[j].path;
					if (matchPath(props.location.pathname, { path: childFullPath, exact: true })) {
						return routes[i].children[j].name;
					}
				}
			}
		}

		return "";
	};

	// For Nav bat toggle
	const handleToggleSidebar = (status) => {
		status = status === isCollapsedSideBar && status === false ? true : status;
		setIsCollapsedSideBat(status)
		setSystemSettings(status);
	}

	// For Nav bat toggle
	const navToggle = (status) => {
		setIsCollapsedSideBat(status);
		setSystemSettings(status);
	}

	const setSystemSettings = (status) => {
		let systemSettings = localStorage.getItem('system-settings');
		systemSettings = systemSettings ? JSON.parse(systemSettings) : { isCollapsedSideBar: false }
		systemSettings.isCollapsedSideBar = status
		localStorage.setItem('system-settings', JSON.stringify(systemSettings))
	}

	return (
		<>
			<Sidebar
				{...props}
				routes={routes}
				screenNavType={isCollapsedSideBar ? "full-screen-nav" : ""} // For Nav bat toggle
				handleNavToggle={navToggle}
				logo={{
					innerLink: "/admin/order-management",
					imgSrc: isCollapsedSideBar ? require("../assets/img/brand/small-logo-blue.png").default : require("../assets/img/brand/white-logo.png").default,// For Nav bat toggle
					imgAlt: "...",
				}}
			/>

			<div className={isCollapsedSideBar ? "main-content full-screen" : "main-content"} ref={mainContent}>
				<Spinner isShow={spinnerCurrentStat} />
				<AdminNavbar
					{...props}
					isOpen={handleToggleSidebar}
					isCollapsedSideBar={isCollapsedSideBar}
					brandText={getBrandText(props.location.pathname)}
				/>
				<Suspense fallback={null}>
					<Switch>
						{getRoutes(routes)}
						{/* <Redirect from="*" to="/admin/dashboard" /> */}
						{/* <Redirect exact={true} from="*" to="/admin/properties" /> */}
					</Switch>
				</Suspense>
				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</>
	);
};

export default Admin;