import React, { Fragment, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { loginAndRedirectToPHP } from 'services/PHP.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { showSpinner } from 'ShowHideSpinner.reducer';

const AutoLoginDecrypt = ({ data, style }) => {
    const dispatch = useDispatch();

    const [isNotUserData, setNotUserData] = useState(false);

    if (isNotUserData) {
        return <Redirect to="auth/login" />
    };

    const handleRedirect = async (e) => {
        e.preventDefault();
        try {
            dispatch(showSpinner(true));
            await loginAndRedirectToPHP(data);
            dispatch(showSpinner(false));
        } catch (error) {
            setNotUserData(true);
            toast["error"]("Something went wrong!");
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <Link to="#" className='custom-link' onClick={handleRedirect} target="_blank" style={style ? style : null}>{data.label}</Link>
        </>
    )
}
export default AutoLoginDecrypt;