import { createSlice } from '@reduxjs/toolkit'
import { defaultValues } from './PropertiesDetailsAPIHelper'

const initialStates = {
  budgetTable: { ...defaultValues().budgetTable },
  propertyAccessPocTable: { ...defaultValues().propertyAccessPocTable },
  lockBoxesGateCodeTable: { ...defaultValues().lockBoxesGateCodeTable }
}

export const PropertiesDrawsOverviewReducer = createSlice({
  name: 'PropertiesDrawsOverview',
  initialState: { ...initialStates },
  reducers: {
    setPropertiesDrawsOverviewData: (state, data) => {
      data?.payload?.forEach(element => { state[element.key] = element.value })
    },
    setBudgetTableData: (state, data) => {
      data?.payload?.forEach(element => { state.budgetTable[element.key] = element.value })
    },
    setPropertyAccessPocTableData: (state, data) => {
      data?.payload?.forEach(element => { state.propertyAccessPocTable[element.key] = element.value })
    },
    setLockBoxesGateCodeTableData: (state, data) => {
      data?.payload?.forEach(element => { state.lockBoxesGateCodeTable[element.key] = element.value })
    },
    resetForm: (state) => {
      for (const key in { ...initialStates }) {
        if (initialStates.hasOwnProperty(key)) {
          state[key] = initialStates[key]
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setPropertiesDrawsOverviewData,
  setBudgetTableData,
  setPropertyAccessPocTableData,
  setLockBoxesGateCodeTableData,
  resetForm
} = PropertiesDrawsOverviewReducer.actions

export default PropertiesDrawsOverviewReducer.reducer