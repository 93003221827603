
import React, { useState, useEffect } from "react";
import { Row, Button, Modal, Col } from "reactstrap";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { PostData } from "../../../services/Api.service";
import { ROLE_ANALYST, ROLE_PROCESSOR, ROLE_ACCOUNT_REP, ROLE_REVIEWER } from "../Common/Constants";
import { sendExceptionEmail } from "components/Common/Helpers";
import * as moment from "moment";
import "./style.scss";

const AddEditSubTopic = ({ propHandleModal, onUpdateSubTopic, selectedRow }) => {
    const [isProcess, setProcess] = useState(false);
    const [flag, setFlag] = useState(true);
    const [update, setUpdate] = useState(moment());
    const [fields, setFields] = useState({});
    const [topics, setTopics] = useState([]);
    const [recipient, setRecipient] = useState([]);
    const [selectRecipientError, setSelectRecipientError] = useState("");
    const [isOpenEditSubTopicModal, setIsOpenEditSubTopicModal] = useState(false);
    let [recipientArray] = useState([]);

    useEffect(() => {
        if (propHandleModal.current) {
            handleNewConversation();
        }
        handleSubTopicModal(); //eslint-disable-next-line 
        if (Object.keys(selectedRow).length > 0 && flag) {
            let rowRecipients = selectedRow.recipients.split(',');
            rowRecipients.forEach((e) => recipientArray.push(JSON.parse(e)));
        }
    }, [propHandleModal.current, selectedRow, update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNewConversation = async () => {
        try {
            const topicData = await PostData("list/get-topics", { limit: "all" });
            const responseTopicJson = topicData.data.topics.rows;
            setTopics(responseTopicJson);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    };

    const handleSubTopicModal = () => {
        setIsOpenEditSubTopicModal(propHandleModal.current);
    };

    const closeModal = (state) => {
        if (!isProcess) {
            state = !state;
            setIsOpenEditSubTopicModal(state);
            propHandleModal.current = false;
            setUpdate(moment());
        }
    };

    // Handle Input Data
    const handleInput = (e) => {
        if (e.target.name === "role") {
            if (e.target.checked) {
                recipient.push(parseInt(e.target.value));
            } else {
                const index = recipient.indexOf(parseInt(e.target.value));
                if (index !== -1) {
                    recipient.splice(index, 1);
                }

                if (Object.keys(selectedRow).length > 0) {
                    const index1 = recipientArray.indexOf(parseInt(e.target.value));
                    if (index1 !== -1) {
                        recipientArray.splice(index1, 1);
                    }
                }
            }
            setRecipient(recipient);
            setSelectRecipientError("")
        }
        else {
            fields[e.target.name] = e.target.value;
        }
        setFields(fields);
    };

    const handleEditSubTopicSubmit = async () => {
        try {
            const finalRecipientArray = recipientArray.concat(recipient);
            fields['recipients'] = finalRecipientArray.join(',');
            if (!fields['recipients']) {
                setSelectRecipientError("Please select recipient")
                return false
            }
            setProcess(true);
            let routeVar = "conversation/add-sub-topic";
            if (Object.keys(selectedRow).length > 0) {
                fields['select_topic'] = fields['select_topic'] ? fields['select_topic'] : selectedRow.topic_id;
                routeVar = "conversation/edit-sub-topic/" + selectedRow.subtopic_id;
            }
            let addEditDataResult = await PostData(routeVar, fields);
            if (addEditDataResult) {
                toast["success"](addEditDataResult.message);
                propHandleModal.current = false;
                setIsOpenEditSubTopicModal(false);
                setProcess(false);
                setFlag(false);
                onUpdateSubTopic(fields['select_topic'], false);
            }
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error);
        }
    };

    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={isOpenEditSubTopicModal} toggle={() => closeModal(isOpenEditSubTopicModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">{(selectedRow.subtopic_name === undefined) ? "Add" : "Edit"} Sub Topic</h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal(isOpenEditSubTopicModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleEditSubTopicSubmit(e)}>
                        <Row>
                            <Col xs="12" sm="4" md="4">
                                <label>Topic</label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <div className="custom-radio-btn inline">
                                    {topics.map(function (topic) {
                                        return (
                                            <div key={topic.topic_name}>
                                                <input type="radio" defaultChecked={selectedRow.topic_id === topic.id ? "true" : ""} id={topic.id} name="select_topic" value={topic.id} onChange={(e) => handleInput(e)} />
                                                <label htmlFor={topic.id}>{topic.topic_name}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                            <Col xs="12" sm="4" md="4">
                                <label className="mt-2">
                                    Sub Topic
                                </label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <AvGroup>
                                    <AvField
                                        name="subtopic_name"
                                        value={selectedRow.subtopic_name}
                                        onChange={(event) => handleInput(event)}
                                        className="form-control"
                                        placeholder="Sub Topic"
                                        type="text"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </Col>
                            <Col xs="12" sm="4" md="4">
                                <label>
                                    Recipient
                                </label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <div>
                                    <div className="custom-control custom-control-alternative custom-checkbox mb-3" sm="2">
                                        <input className="custom-control-input" type="checkbox" value={ROLE_ANALYST}
                                            name={"role"}
                                            id={"role_analyst"}
                                            onChange={(e) => handleInput(e)}
                                            defaultChecked={(selectedRow.recipients && selectedRow.recipients.includes(ROLE_ANALYST)) ? "checked" : ""}
                                        />
                                        <label className="custom-control-label" htmlFor={"role_analyst"}>Analyst</label>
                                    </div>
                                    <div className="custom-control custom-control-alternative custom-checkbox mb-3" sm="2">
                                        <input className="custom-control-input" type="checkbox" value={ROLE_PROCESSOR}
                                            name={"role"}
                                            id={"role_processor"}
                                            title={"processor"}
                                            onChange={(e) => handleInput(e)}
                                            defaultChecked={selectedRow.recipients && selectedRow.recipients.includes(ROLE_PROCESSOR) ? true : false}
                                        />
                                        <label className="custom-control-label" htmlFor={"role_processor"}>Processor</label>
                                    </div>
                                    <div className="custom-control custom-control-alternative custom-checkbox mb-3" sm="2">
                                        <input className="custom-control-input" type="checkbox" value={ROLE_ACCOUNT_REP}
                                            name={"role"}
                                            id={"role_acct_rep"}
                                            onChange={(e) => handleInput(e)}
                                            defaultChecked={(selectedRow.recipients && selectedRow.recipients.includes(ROLE_ACCOUNT_REP)) ? true : false}
                                        />
                                        <label className="custom-control-label" htmlFor={"role_acct_rep"}>Account Rep</label>
                                    </div>
                                    <div className="custom-control custom-control-alternative custom-checkbox mb-3" sm="2">
                                        <input className="custom-control-input" type="checkbox" value={ROLE_REVIEWER}
                                            name={"role"}
                                            id={"role_reviewer"}
                                            onChange={(e) => handleInput(e)}
                                            defaultChecked={(selectedRow.recipients && selectedRow.recipients.includes(ROLE_REVIEWER)) ? true : false}
                                        />
                                        <label className="custom-control-label" htmlFor={"role_reviewer"}>Reviewer</label>
                                    </div>
                                </div>
                                {selectRecipientError && (
                                    <div className="text-danger mt-2">
                                        {selectRecipientError}
                                    </div>
                                )}
                            </Col>

                            <Col xs="12" sm="4" md="4">
                                <label className="mt-2">
                                    Description
                                </label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <AvGroup>
                                    <AvField
                                        name="subtopic_description"
                                        value={selectedRow.subtopic_description}
                                        onChange={(event) => handleInput(event)}
                                        className="form-control"
                                        placeholder="Description"
                                        type="text"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </Col>
                            <Col md="12" className="text-right mt-2">
                                {isProcess ?
                                    <Button size="sm" type="button" color="warning" >
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline type="button" onClick={() => closeModal(isOpenEditSubTopicModal)}>
                                            Cancel
                                        </Button>
                                        <Button size="sm" color="info">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    )
}
export default AddEditSubTopic;