import React, { useState, useEffect } from "react";
import { PostData } from "../../../services/Api.service";
import * as moment from "moment";
import { toast } from 'react-toastify';
import MessageList from "../Conversation/MessageList";
import MessageType from "../Conversation/MessageType";
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";
import SendMessageForm from "../Conversation/SendMessageForm";
import { Modal, Row, Col } from "reactstrap";
import { loggedInUser, displayUserName, sendExceptionEmail } from "components/Common/Helpers.js"
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt";

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
const userData = loggedInUser();
const ChatModal = ({ standaloneType, conversationData, propHandleModal, onCloseChat, propertyId, onUpdateMessage }) => { // cTab

    let topic_subtopic = conversationData.topic_subtopic ? conversationData.topic_subtopic.split(":") : "";
    let [update, setUpdate] = useState(moment());
    let [messages, setMessages] = useState([]);
    let [lastMessageRecipients, setLastMessageRecipients] = useState([]);
    let [editMsg, setEditMsg] = useState({});
    let [isOpenChatModal, setIsOpenChatModal] = useState(false);

    useEffect(() => {
        if (propHandleModal.current) {
            setEditMsg({});
            getConversationMessages(userData.is_RV_user ? [MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL, MESSAGE_TYPES.INSPECTOR] : [MESSAGE_TYPES.CLIENT]);
        }
    }, [propHandleModal.current, update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleUserModal = () => {
        setIsOpenChatModal(propHandleModal.current);
    };

    let getEditedMessage = (data) => {
        setEditMsg(data)
    }

    let closeModal = (state, isFromChat = 0) => {
        state = !state;
        setIsOpenChatModal(state);
        propHandleModal.current = false;
        setUpdate(moment());
        if (isFromChat === 1)
            onCloseChat();
    }

    let getConversationMessages = async (messageType) => {
        try {
            let messageData = []; let lastMessageData = [];
            let conversation_id = conversationData.id;
            if (conversation_id) {
                let reportMessages = await PostData("list/get-messages", { conversation_id, message_type: messageType.length ? messageType.join() : [0].join() });
                let responseMessageJson = reportMessages.data.messages;
                Object.keys(responseMessageJson).forEach(index => {
                    let messageObj = {};
                    messageObj.id = responseMessageJson[index]['id'];
                    messageObj.conversation_id = responseMessageJson[index].conversation_id;
                    messageObj.property_file_id = responseMessageJson[index]['property_file_id'] ? responseMessageJson[index]['property_file_id'] : null;
                    messageObj.message_detail = responseMessageJson[index]['report_order_message_conversation'].issue_detail;
                    messageObj.recipient_role = (responseMessageJson[index].role_recipients !== null) ? responseMessageJson[index].role_recipients : '';
                    messageObj.recipient_user = (responseMessageJson[index]['to_user']) ? responseMessageJson[index]['to_user'].display_users : '';
                    messageObj.to_id = responseMessageJson[index]['from_user'] ? responseMessageJson[index]['from_user'].user_id : '';
                    messageObj.primary_email_users = responseMessageJson[index].primary_email_users ? responseMessageJson[index].primary_email_users : '';
                    messageObj.from_id = responseMessageJson[index]['from_user'] ? responseMessageJson[index]['from_user'].user_id : '';
                    messageObj.to = displayUserName(responseMessageJson[index]['from_user'], userData.is_RV_user);
                    messageObj.from = responseMessageJson[index]['from_user'] ? responseMessageJson[index]['from_user'].first_name + ' ' + responseMessageJson[index]['from_user'].last_name : '';
                    messageObj.text = responseMessageJson[index].message;
                    messageObj.messageType = responseMessageJson[index].message_type;
                    messageObj.report_order_id = responseMessageJson[index].report_order_id;
                    messageObj.inspection_order_id = responseMessageJson[index].inspection_order_id;
                    messageObj.toId = responseMessageJson[index]['to_user'] ? responseMessageJson[index]['to_user'].user_id : '';
                    messageObj.file_url = (responseMessageJson[index].file_url !== '') ? responseMessageJson[index].file_url : '';
                    messageObj.file_name = (responseMessageJson[index].file_name !== '') ? responseMessageJson[index].file_name : '';
                    messageObj.messageTime = responseMessageJson[index].message_datetime ? moment(responseMessageJson[index].message_datetime).format('MMM D hh:mm a') : '-';
                    messageData.push(messageObj);
                });
                if (responseMessageJson.length) {
                    let msgObjLength = (responseMessageJson.length - 1);
                    lastMessageData = responseMessageJson[msgObjLength].role_id.split(',');
                    setLastMessageRecipients(lastMessageData);
                }
            }

            handleUserModal(); //eslint-disable-next-line 
            setMessages(messageData);
            setEditMsg({});
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    }

    return (
        <>
            <Modal className="modal-dialog-centered" style={{ maxWidth: "70%" }} isOpen={isOpenChatModal} toggle={() => closeModal(isOpenChatModal)}>
                <div className="modal-header modal-header-colored">
                    <span className="w-25">{topic_subtopic[0]}</span>

                    <h4 className="text-center text-white w-50">
                        <b>
                            {
                                userData.is_RV_user ? <AutoLoginDecrypt key={propertyId} data={{ label: conversationData.property_address, url: `${phpUrl}valuation/deals/details/${propertyId}` }} style={{ color: 'white' }} />
                                    : conversationData.property_address
                            }
                        </b> </h4>
                    <span className="text-right w-25">{topic_subtopic[1]}</span>

                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal(isOpenChatModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Row>
                        <Col xs="12" sm="6" md="6">
                            <MessageType onChangeMessageType={getConversationMessages} />
                            <MessageList messages={messages} onEditDeleteMessage={getConversationMessages} onEditMsg={getEditedMessage} />
                        </Col>
                        <Col xs="12" sm="6" md="6">
                            <SendMessageForm standaloneType={standaloneType} conversation={conversationData} lastMessageData={lastMessageRecipients} onSendMessage={getConversationMessages} onMarkResolved={closeModal} onEditSetMsg={editMsg} onUpdateMessage={onUpdateMessage} />
                        </Col>
                    </Row>


                </div>
            </Modal>
        </>
    )
}
export default ChatModal;