import React, { useState } from "react";
import { toast } from "react-toastify";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { PropTypes } from "prop-types";
import OrderManagementSearch from "views/modules/OrderManagementSearch/Index.js";
import { PostData } from "../../services/PostData";
import Spinner from "components/Common/Spinner.js";
import { loginUserAvatarUrl, checkRoles, updateLoginLogoutLocalStorage, loggedInUser } from "components/Common/Helpers";
import { sendExceptionEmail } from "components/Common/Helpers";

// var ps;
function handleMultiMenu(e, data, setIsOpen, isChildren, prop, isParent) {
	e.preventDefault();
	if (isChildren) {
		if (data === true) {
			setIsOpen(false);
			if (isParent !== prop.name) {
				setIsOpen(true);
			}
		} else {
			setIsOpen(true);
		}
	}
}

const Sidebar = (props) => {
	const [collapseOpen, setCollapseOpen] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [isParent, setParent] = useState("none");
	const [isLogout, setISLogout] = useState(false);
	const userData = loggedInUser();

	/*
   | Handle Logout
   */
	const handleLogout = async (e) => {
		try {
			setISLogout(true);
			const userData = loggedInUser() ? loggedInUser() : "";
			const result = await PostData("auth/logout", { token: userData.token })

			let responseJson = result.data;

			if (responseJson.status === 200) {
				e.preventDefault();

				localStorage.removeItem("userData");
				localStorage.removeItem("OCT"); //Other client token
				updateLoginLogoutLocalStorage(false);
				setISLogout(false);
				this.props.history.push("/auth/login");
			} else {
				toast(responseJson.message);
				setISLogout(false);
			}
		} catch (error) {
			toast(error);
			setISLogout(false);
			await sendExceptionEmail(error, { componentName: 'sidebar', functionName: 'handleLogout', data: {} });
		}
	}

	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};

	// closes the collapse
	const closeCollapse = (e, isOpen, setIsOpen, children, setParent, prop) => {
		props.handleNavToggle(false); // For Nav bat toggle

		setParent(prop.name)
		setCollapseOpen(false);
		if (children) {
			setCollapseOpen(true);
			handleMultiMenu(e, isOpen, setIsOpen, children, prop, isParent)
		} else if (setIsOpen) {
			setIsOpen(false);
		}
	};

	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			if (prop.is_visible === true) {
				return (
					// Parent tree
					<NavItem key={key} className={prop.is_show_label === false ? "hide" : ""}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={e => prop.children ? closeCollapse(e, isOpen, setIsOpen, prop.children, setParent, prop) : null}
							activeClassName="active"
						>
							{prop.iconImage ?
								<img alt="" src={prop.iconImage} title={prop.name} />
								:
								<i className={prop.icon} title={prop.name} />
							}
							{prop.name} {prop.children ? isOpen && prop.name === isParent ? <i className="ni ni-fat-delete dv-minus"></i> : <i className="ni ni-fat-add dv-plus"></i> : null}
						</NavLink>
						{
							// Submenu tree
							prop.children && isOpen && prop.name === isParent ?
								<ul className="sub-menu" >
									{prop.children.map((value, index) => {


										if (value.is_visible === true) {

											return (
												<NavItem key={index} className={value.is_show_label === false ? "hide" : "sub-item"}>
													<NavLink
														to={value.layout + value.path}
														tag={NavLinkRRD}
														onClick={e => closeCollapse(e, false, "", false, setParent, prop)}
														activeClassName="active"
													>
														{value.iconImage ?
															<img alt="" src={value.iconImage} />
															:
															<i className={value.icon} title={value.name} />
														}
														{value.name}
													</NavLink>
												</NavItem>
											)
										} else {
											return "";
										}
									})}

								</ul>
								: null
						}

					</NavItem>
				);
			} else {
				return null;
			}
		});
	};

	const { routes, logo, screenNavType } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank",
		};
	}

	return (
		<Navbar className={screenNavType + " navbar-vertical fixed-left navbar-light bg-white"} expand="md" id="sidenav-main">
			<Spinner isShow={isLogout} />
			<Container fluid>
				{/* Toggler */}
				<button
					className="navbar-toggler"
					type="button"
					onClick={toggleCollapse}
				>
					<span className="navbar-toggler-icon" />
				</button>
				{/* Brand */}
				{logo ? (
					<NavbarBrand className="pt-0" {...navbarBrandProps}>
						<img
							alt={logo.imgAlt}
							className="navbar-brand-img"
							src={logo.imgSrc}
						/>
					</NavbarBrand>
				) : null}
				{/* User */}
				<Nav className="align-items-center d-md-none">
					<UncontrolledDropdown nav>
						<DropdownToggle nav>
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle">
									<img alt="..." loading="lazy" src={loginUserAvatarUrl(`${userData?.first_name} ${userData?.last_name}`)} />
								</span>
							</Media>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem className="noti-title text-center" tag="div">
								<h4 className="text-overflow m-0">{userData?.first_name + " " + userData?.last_name}</h4>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem href={userData.report_order_company_id === userData.company_id ? "/admin/change-password" : "/client/change-password"}>
								<i className="fa fa-key" aria-hidden="true"></i>
								<span>Change Password</span>
							</DropdownItem>
							<DropdownItem href="#" onClick={(e) => handleLogout(e)}>
								<i className="ni ni-user-run" />
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className="navbar-collapse-header d-md-none">
						<Row>
							{logo ? (
								<Col className="collapse-brand" xs="6">
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</a>
									)}
								</Col>
							) : null}
							<Col className="collapse-close" xs="6">
								<button
									className="navbar-toggler"
									type="button"
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					<OrderManagementSearch collapseAfterSearch="yes" afterSearch={toggleCollapse} className="navbar-search navbar-search-light form-inline mt-4 ml-lg-auto mb-3 d-md-none" />

					{/* Navigation */}
					<Nav navbar>{createLinks(routes)}</Nav>

					{/* Divider */}
					<hr className="my-3" />
				</Collapse>
				{checkRoles(['TECH', 'ADMINISTRATOR']) === true && process.env.REACT_APP_SERVER_ENVIRONMENT === "production" ? <Link relative="path" className="health-link" target="_blank" to={{ pathname: "https://7wjywlbq7ecvmjbdkv6lfk4lqu0upwji.lambda-url.us-east-2.on.aws/" }}><i className="fa fa-link" aria-hidden="true"></i> Server Health</Link> : ""}
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		innerLink: PropTypes.string,
		outterLink: PropTypes.string,
		imgSrc: PropTypes.string.isRequired,
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;