import { createSlice } from '@reduxjs/toolkit'
import { GetData } from "../../../src/services/Api.service";
import { sendExceptionEmail } from "../../../src/components/Common/Helpers";

// You can fetch the data asynchronously inside a Redux thunk action.
export const fetchTimeTrackingTaskIds = () => async (dispatch) => {
    try {
        if (process.env.REACT_APP_SERVER_ENVIRONMENT === "production") {
            let params = {
                system: 'OMS',
            };

            const response = await GetData("orders/get-time-tracking-task-data", params);
            dispatch(setData(response.data.data));
        } else {
            dispatch(setData([]));
        }
    } catch (error) {
        dispatch(setData([]));
        await sendExceptionEmail(error, { componentName: 'taskIdsList.reducer', functionName: 'fetchTimeTrackingTaskIds', data: {} });
    }
};

export const taskIdsListReducer = createSlice({
    name: 'taskIdsList',
    initialState: {
        data: {}, // Initialize data as an empty object
    },
    reducers: {
        setData: (state, action) => {
            // Update data with the entire payload value
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setData } = taskIdsListReducer.actions

export default taskIdsListReducer.reducer