import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { PostData } from "services/Api.service";
import { openInNewTab } from "components/Common/Helpers";
import { sendExceptionEmail } from "components/Common/Helpers";

import "./MarketPlaceUrls.scss";
import MarketPlaceInfo from "./MarketPlaceInfo";

const MarketPlaceUrls = ({ address, size = 25, googleSearch = false }) => {

    const [compAddress, setCompAddress] = useState("")
    const [zillowLinkUrl, setZillowUrl] = useState("")
    const [realtorUrl, setRealtorUrl] = useState("https://www.realtor.com/")
    const [redfinUrl, setRedfinUrl] = useState("https://www.redfin.com/")
    const [regridUrl, setRegridUrl] = useState("https://app.regrid.com/search?query=")
    const [googleMapUrl] = useState("https://www.google.com/maps/place/")
    const [googleSearchUrl, setGoolgeSearchUrl] = useState("https://www.google.com")
    useEffect(() => {
        async function fetchData() {
            await fetchPropertyUrls(address);
        }

        fetchData();
    }, [address?.address]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchPropertyUrls = async (params) => {
        try {
            setRealtorUrl("https://www.realtor.com/");
            setRedfinUrl("https://www.redfin.com/");

            let fullAddress = params.address + ", " + params.city + ", " + params.state + ", " + params.postal_code;

            let zLinkAddress = fullAddress.replace(/ /g, '-');
            let zLinkUrl = "https://www.zillow.com/homes/" + zLinkAddress + "_rb/";

            let googleSearchAddress = fullAddress.split(' ').join('+');
            let googleSearchLinkUrl = 'https://www.google.com/search?q=' + googleSearchAddress;

            let regridSearchAddress = fullAddress.replace(/\s{2,}/g, ' ').replace(/,+/g, ',').trim()
            let regridLinkUrl = 'https://app.regrid.com/search?query=' + regridSearchAddress + '&context=false&map_id=';

            setZillowUrl(zLinkUrl)
            setCompAddress(fullAddress)
            setGoolgeSearchUrl(googleSearchLinkUrl)
            setRegridUrl(regridLinkUrl)
            const response = await PostData('public/search-property-on-google', params);

            if (response.success && response.data) {
                setRealtorUrl(response.data.realtor);
                setRedfinUrl(response.data.redfin);
            }
        } catch (error) {
            await sendExceptionEmail(error, { componentName: 'MarketPlaceUrls', functionName: 'fetchPropertyUrls', data: params });
        }
    };

    return (
        <>
            <MarketPlaceInfo propReportRow={{
                fullAddress: compAddress,
                zillowUrl: zillowLinkUrl,
                realtorUrl,
                regridUrl,
                redfinUrl,
                googleMapUrl: googleMapUrl + compAddress,
                googleSearchUrl,
                googleSearch
            }} />

            <Button color="secondary" size="sm" disabled={!address.address} title="Zillow" className="marketplace-url-btn" onClick={(e) => openInNewTab(zillowLinkUrl)}>
                <img className="cursor-pointer" alt="Zillow" width={size} src={require("../../assets/img/marketplace/zillow_logo.png").default}
                />
            </Button>

            <CopyToClipboard text={compAddress}>
                <Button color="secondary" size="sm" disabled={!address.address} title="Realtor" className="marketplace-url-btn" onClick={(e) => openInNewTab(realtorUrl)}>
                    <img className="cursor-pointer" alt="Realtor" width={size} src={require("../../assets/img/marketplace/realtor_small.png").default}
                    />
                </Button>
            </CopyToClipboard>

            <CopyToClipboard text={compAddress}>
                <Button color="secondary" size="sm" disabled={!address.address} title="Redfin" className="marketplace-url-btn" onClick={(e) => openInNewTab(redfinUrl)}>
                    <img className="cursor-pointer" alt="Redfin" width={size} src={require("../../assets/img/marketplace/redfin_favicon.ico").default}
                    />
                </Button>
            </CopyToClipboard>

            <Button color="secondary" size="sm" disabled={!address.address} title="Regrid" className="marketplace-url-btn" onClick={(e) => openInNewTab(regridUrl)}>
                <img className="cursor-pointer" alt="Regrid" width={size} src={require("../../assets/img/marketplace/regrid_new.png").default} />
            </Button>

            <Button color="secondary" size="sm" disabled={!address.address} title="Google Map" className="marketplace-url-btn" onClick={(e) => openInNewTab(googleMapUrl + compAddress)}>
                <img className="cursor-pointer" alt="Google Map" width={size} src={require("../../assets/img/marketplace/google-maps.png").default} />
            </Button>

            {googleSearch &&
                <Button color="secondary" size="sm" title="Google Search" className="marketplace-url-btn" onClick={(e) => openInNewTab(googleSearchUrl)}>
                    <img className="cursor-pointer" alt="Google Search" width={size} src={require("../../assets/img/marketplace/google-search.png").default}
                    />
                </Button>
            }
        </>
    );
};

export default MarketPlaceUrls;