import { sendExceptionEmail } from "components/Common/Helpers";
import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { PostData } from "../../../services/Api.service";

const ReportOrderOriginalSource = ({ reportOrderId, inspectionOrderId, onReportOrderOriginalSourceCB, disabled = false }) => {

    let [isOriginalSourceId, setIsOriginalSourceId] = useState(null);
    let [originalPaymentSourceText, setOriginalPaymentSourceText] = useState(null);
    let [isDefaultSourceCheckboxChecked, setIsDefaultSourceCheckboxChecked] = useState(true);

    useEffect(() => {
        setOriginalPaymentData();
    }, [reportOrderId, inspectionOrderId]);// eslint-disable-line react-hooks/exhaustive-deps

    let setOriginalPaymentData = async () => {
        try {

            let result = await PostData("orders/original_payment", { report_order_id: reportOrderId, inspection_order_id: inspectionOrderId })
            let responseJson = result.data?.originalPaymentData ? result.data?.originalPaymentData : {};
            if (responseJson.payment_source_id) {
                setIsOriginalSourceId(responseJson.payment_source_id)
                if (responseJson.company_payment_source) {
                    setOriginalPaymentSourceText(`Deduct amount from account ending with ${responseJson.company_payment_source.last_4} (The source that was used to submit an order).`);
                } else {
                    setOriginalPaymentSourceText(`Deduct amount from the source that was used to submit an order.`);
                }

                onReportOrderOriginalSourceCB({
                    payment_source: {
                        payment_method: 'USE_EXISTING_SOURCE',
                        payment_source_id: responseJson.payment_source_id
                    },
                    isOriginalSource: true,
                    selectionText: ""
                });
            } else {
                setIsOriginalSourceId(null);
                onReportOrderOriginalSourceCB(null);
            }
        } catch (errors) {
            setIsOriginalSourceId(null);
            onReportOrderOriginalSourceCB(null);
            await sendExceptionEmail(errors);
        }
    };

    let handleOriginalSourceCheckbox = (e) => {

        if (e.target.checked === true) {
            setIsDefaultSourceCheckboxChecked(true);
            onReportOrderOriginalSourceCB({
                payment_source: {
                    payment_method: 'USE_EXISTING_SOURCE',
                    payment_source_id: isOriginalSourceId
                },
                isOriginalSource: true,
                selectionText: ""
            });

        } else {
            setIsDefaultSourceCheckboxChecked(false);
            onReportOrderOriginalSourceCB(null);
        }
    };

    return (
        <>
            {isOriginalSourceId && <Row>
                <Col xs="12" sm="12" md="12" lg="12" className="mb-3">
                    <div className="custom-control custom-checkbox">
                        <input id="all_client" name="all_client" className="custom-control-input" type="checkbox" value='0'
                            onChange={(e) => handleOriginalSourceCheckbox(e)}
                            defaultChecked={isDefaultSourceCheckboxChecked === true ? "checked" : ""}
                            disabled={disabled}
                        />
                        <label className="custom-control-label" htmlFor="all_client">{originalPaymentSourceText}</label>
                    </div>
                </Col>
            </Row>
            }
        </>
    );
};

export default ReportOrderOriginalSource;