export const defaultValues = () => {
  return {
    propertyFilterParams: {
      showHiddenProps: true,
      mapDrawings: [],
      mapShapeFilteredProps: [],
      fields: {
        aiv: {},
        arv: {},
        budget: {},
        completion_percent: {},
        val_date: {},
        draw_date: {},
      },
    },
    budgetTable: {
      isProcess: false,
      currentPage: 1,
      recordsPerPage: 5,
      totalRecordSize: 0,
      totalRecords: 0,
      sortFieldName: 'id',
      sortDirection: 'desc',
    },
    propertyAccessPocTable: {
      isProcess: false,
      currentPage: 1,
      recordsPerPage: 5,
      totalRecordSize: 0,
      totalRecords: 0,
      sortFieldName: 'id',
      sortDirection: 'desc',
    },
    lockBoxesGateCodeTable: {
      isProcess: false,
      currentPage: 1,
      recordsPerPage: 5,
      totalRecordSize: 0,
      totalRecords: 0,
      sortFieldName: 'inspection_order_id',
      sortDirection: 'desc',
    }
  }
}