
import React, { useState, useEffect } from "react";
import Spinner from "components/Common/Spinner.js";
import { PostData } from "../../../services/Api.service";
import * as moment from "moment";
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { Row, Button, Modal, Col } from "reactstrap";
import { sendExceptionEmail, checkRoles, checkRolesWithLevel } from "components/Common/Helpers.js"
import AddEditTopic from "./AddEditTopic.js";
import { ROLE_ANALYST, ROLE_PROCESSOR, ROLE_ACCOUNT_REP, ROLE_REVIEWER } from "../Common/Constants";
import AddEditSubTopic from "./AddEditSubTopic.js";

const AddEditConversationModal = ({ propHandleModal, reportInspectionId, standaloneType, currentRow, onUpdateConversation }) => { // cTab
    let [isProcess, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [topics, setTopics] = useState([]);
    let [subtopics, setSubTopics] = useState([]);
    let [fields, setFields] = useState({});
    let [selectTopicError, setSelectTopicError] = useState("");
    let [recipients, setRecipients] = useState([]);
    let [selectSubTopicError, setSelectSubTopicError] = useState("");
    let [selectRecipientError, setSelectRecipientError] = useState("");
    let [toRole, setToRole] = useState([]);
    let [oldRecipients, setOldRecipients] = useState([]);
    let [isOpenAddEditConversationModal, setIsOpenAddEditConversationModal] = useState(false);
    let isEdit = React.useRef(false);
    let isOpenEditTopicModal = React.useRef(false);
    let isOpenEditSubTopicModal = React.useRef(false);
    useEffect(() => {
        onHandleSubTopicChange(currentRow.subtopic_id)
        recipients.push({ id: 'analyst', val: ROLE_ANALYST, text: 'Analyst' });
        recipients.push({ id: 'processor', val: ROLE_PROCESSOR, text: 'Processor' });
        recipients.push({ id: 'acct_rep', val: ROLE_ACCOUNT_REP, text: 'Account Rep' });
        recipients.push({ id: 'reviewer', val: ROLE_REVIEWER, text: 'Reviewer', });
        setRecipients(recipients)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (propHandleModal.current) {
            handleNewConversation();
            handleUserModal(); //eslint-disable-next-line 
        }
        if (Object.keys(currentRow).length > 0) {
            onHandleTopicChange(currentRow.topic_id, isEdit = true); // eslint-disable-line react-hooks/exhaustive-deps
            fields['select_topic'] = currentRow.topic_id;
            fields['select_sub_topic'] = currentRow.subtopic_id;
            fields['issue_detail'] = currentRow.issue_detail;
        }
    }, [propHandleModal.current]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update])

    let handleUserModal = () => {
        setIsOpenAddEditConversationModal(propHandleModal.current);
    };

    let closeModal = (state) => {
        state = !state;
        setIsOpenAddEditConversationModal(state);
        propHandleModal.current = false;
        setUpdate(moment());
    }

    let handleNewConversation = async () => {
        try {
            let topicData = await PostData("list/get-topics", { limit: 'all' });
            let responseTopicJson = topicData.data.topics.rows;
            setTopics(responseTopicJson);
            if (isOpenEditTopicModal.current === true) {
                isOpenEditTopicModal.current = false
            }
            setUpdate(moment());
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    }

    let onHandleTopicChange = async (topic_id, editMode) => {
        try {
            let subTopicData = await PostData("list/get-sub-topics", { topic_id: topic_id })
            let responseSubTopicJson = subTopicData.data.subtopics;
            setSubTopics(responseSubTopicJson);
            if (editMode === false)
                delete fields['select_sub_topic']
            setSelectTopicError("")
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error);
        }
    }

    let onHandleSubTopicChange = async (sub_topic_id) => {
        try {
            let internalIssueRecipientData = await PostData("list/get-internal-issues-recipients", { sub_topic_id: sub_topic_id })
            let response = internalIssueRecipientData.data.internalIssuesRecipients;
            let recipientsArray = response[0].recipients.split(',');
            recipientsArray = recipientsArray.map(Number);
            oldRecipients = recipientsArray.map(Number);
            setToRole(recipientsArray);
            setOldRecipients(oldRecipients);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error);
        }
    }

    let handleRecipients = (e) => {
        if (e.target.checked) {
            toRole.push(parseInt(e.target.value));
        } else {
            let index = toRole.indexOf(parseInt(e.target.value));
            if (index !== -1) {
                toRole.splice(index, 1);
            }
        }
        setToRole(toRole);
        setSelectRecipientError("")
        setUpdate(moment());
    }

    let handleEditConversationSubmit = async () => {
        try {
            if (!fields['select_topic']) {
                setSelectTopicError("Please select topic")
                return false
            }
            if (!fields['select_sub_topic'] && subtopics.length > 0) {
                setSelectSubTopicError("Please select sub topic")
                return false
            }
            if (toRole.length === 0) {
                setSelectRecipientError("Please select recipient")
                return false
            }
            setProcess(true);
            let routeVar = "conversation/add-conversation";
            if (Object.keys(currentRow).length > 0) {
                routeVar = "conversation/edit-conversation/" + currentRow.id;
            } else {
                if (standaloneType === "1") { // cTab === 'awaiting_inspection'
                    fields['inspection_order_id'] = reportInspectionId;
                } else {
                    fields['report_order_id'] = reportInspectionId;
                }
            }
            let addEditDataResult = await PostData(routeVar, fields);
            const areRecipientsEqual = (toRole, oldRecipients) => {
                return toRole.length === oldRecipients.length && toRole.every((value, index) => value === oldRecipients[index]);
            };
            if (!areRecipientsEqual(toRole, oldRecipients)) {
                let subTopicRecipients = toRole.join(',');
                await PostData("conversation/edit-sub-topic/" + currentRow.subtopic_id, { report_id: reportInspectionId, conversation_id: currentRow.id, recipients: subTopicRecipients });
            }
            toast["success"](addEditDataResult.message);
            propHandleModal.current = false;
            setIsOpenAddEditConversationModal(false);
            onUpdateConversation(1);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error);
        }
    }

    let handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'select_sub_topic')
            setSelectSubTopicError("")
        fields[name] = value;
        setFields(fields);
    }

    // Handle Edit Topic popup
    let handleEditTopicModal = (e) => {
        e.preventDefault();
        isOpenEditTopicModal.current = true;
        setUpdate(moment());
    };

    // Handle Edit Topic popup
    let handleEditSubTopicModal = (e) => {
        e.preventDefault();
        isOpenEditSubTopicModal.current = true;
        setUpdate(moment());
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Modal className="modal-dialog-centered edit-user-modal" style={{ maxWidth: "50%" }} isOpen={isOpenAddEditConversationModal} toggle={() => closeModal(isOpenAddEditConversationModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">
                        {(Object.keys(currentRow).length > 0) ? 'Edit' : 'Add'} Conversation
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal(isOpenAddEditConversationModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleEditConversationSubmit(e)}>
                        <>
                            <Row>
                                <Col>
                                    <label><b>Topic</b></label>
                                    {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || checkRolesWithLevel(['ANALYST'], ">=", 3)) &&
                                        <Button id={"add_new_topic"} className="float-right mb-3" color="success" size="sm" type="button" onClick={e => handleEditTopicModal(e)}>
                                            Add New Topic
                                        </Button>
                                    }
                                    <div className="custom-radio-btn inline">
                                        {topics.map(function (topic) {
                                            return (
                                                <div key={topic.topic_name}>
                                                    <input type="radio" defaultChecked={currentRow.topic_id === topic.id ? "true" : ""} id={topic.topic_name} name="select_topic" value={topic.id} onChange={(e) => handleInput(e)} onClick={(e) => onHandleTopicChange(parseInt(topic.id), isEdit)} />
                                                    <label htmlFor={topic.topic_name}>{topic.topic_name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {selectTopicError && (
                                        <label className="text-danger">
                                            {selectTopicError}
                                        </label>
                                    )}
                                </Col>
                            </Row>
                        </>
                        {(subtopics.length > 0 || Object.keys(currentRow).length > 0) &&
                            <>
                                <Row>
                                    <Col>
                                        <label><b>Sub Topic</b></label>
                                        {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || checkRolesWithLevel(['ANALYST'], ">=", 3)) &&
                                            <Button id={"add_new_sub_topic"} className="float-right mb-3" color="success" size="sm" type="button" onClick={e => handleEditSubTopicModal(e)}>
                                                Add New Sub Topic
                                            </Button>
                                        }
                                        <div className="custom-radio-btn inline">
                                            {subtopics.map(function (subtopic) {
                                                return (
                                                    <div key={subtopic.id}>
                                                        <input type="radio" defaultChecked={currentRow.subtopic_id === subtopic.id ? "true" : ""} id={subtopic.subtopic_name} name="select_sub_topic" value={subtopic.id} onChange={(e) => handleInput(e)} onClick={(e) => onHandleSubTopicChange(parseInt(subtopic.id))} />
                                                        <label htmlFor={subtopic.subtopic_name}>{subtopic.subtopic_name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {selectSubTopicError && (
                                            <label className="text-danger">
                                                {selectSubTopicError}
                                            </label>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col-sm-auto">
                                        <label><b>To</b></label>
                                    </div>
                                    <div className="col-sm-9">
                                        {selectRecipientError && (
                                            <div className="text-danger mt-2">
                                                {selectRecipientError}
                                            </div>
                                        )}
                                        <div>
                                            <li className="custom-control custom-checkbox mb-3" sm="2">
                                                {recipients && recipients.map((recipient, index) => {
                                                    return (
                                                        <label key={index}>
                                                            <input className="custom-control-input" type="checkbox" value={recipient.val}
                                                                name={"role"}
                                                                key={index}
                                                                id={recipient.id}
                                                                onChange={(e) => handleRecipients(e)}
                                                                checked={(toRole && toRole.includes(recipient.val)) ? true : false}
                                                            />
                                                            <label className="custom-control-label mr-5" htmlFor={recipient.id}>{recipient.text}</label>
                                                        </label>
                                                    )
                                                })}
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <AvGroup className="text-left">
                                    <AvField
                                        name="issue_detail"
                                        value={currentRow.issue_detail}
                                        onChange={(e) => handleInput(e)}
                                        className="form-control"
                                        placeholder="Issue detail"
                                        type="textarea"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            }
                                        }}
                                    />
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <Button size="sm" color="danger" outline type="button" onClick={() => closeModal(isOpenAddEditConversationModal)}>
                                    Cancel
                                </Button>
                                <Button size="sm" color="info">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
            {isOpenEditTopicModal.current &&
                <AddEditTopic propHandleModal={isOpenEditTopicModal} onUpdateTopic={handleNewConversation} selectedRow={[]} />
            }
            {isOpenEditSubTopicModal.current &&
                <AddEditSubTopic propHandleModal={isOpenEditSubTopicModal} onUpdateSubTopic={onHandleTopicChange} selectedRow={[]} />
            }
        </>
    )
}
export default AddEditConversationModal;